body, button, .bn-onboard-modal {
  font-family: Capsules, monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, div, span, p, textarea, input, a {
  transition: font-weight .1s ease-out !important;
}

h1 {
  font-weight: 600;
}

.hov-fat:hover {
  font-weight: 700 !important;
}

.bn-onboard-modal-content-header-icon {
  display: none !important;
}

.bn-onboard-prepare-error {
  background: transparent !important;
}

.bn-onboard-select-wallet-info, .bn-onboard-prepare-button {
  color: #00ffff !important;
}

.bn-onboard-prepare-button {
  border-color: #00ffff !important;
}

.bn-onboard-modal-content {
  background: black !important;
  border: 2px solid #fff;
  max-height: 80vh;
  overflow: auto !important;
}

.bn-onboard-custom.bn-onboard-dark-mode-background-hover {
  border: 2px solid transparent;
}

.bn-onboard-custom.bn-onboard-dark-mode-background-hover:hover {
  background: transparent !important;
  border-color: #fff ;
}

body {
  margin: 0;
  background: black;
  color: #fff;
  /* color: #947641; */
  /* color: #ffd219; */
  line-height: 1.5;
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
}

a, button {
  font-weight: 600;
  text-decoration: none;
}

button {
  color: #fff;
}

a {
  color: #fff;
}

a.light {
  font-weight: 200;
}

a:hover {
  font-weight: bold;
}

a.light:hover {
  font-weight: 300;
}

::selection {
  background: #ffff0044;
}

button {
  background: transparent;
  border: none;
}

input, textarea {
  font-family: 'Capsules';
  background: transparent;
  border: none;
  outline: none;
  color: white;
  white-space: pre-wrap !important;
}

textarea::placeholder {
  color: #fff;
}

input::placeholder {
  opacity: .65;
}

pre.caret-underscore > span {
  animation: blink 1s step-end infinite;
  border-bottom: 2px solid white;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #000;
  border: 2px solid #fff;
  margin-top: -9px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #fff;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #fff;
}

select {
  background: transparent;
  color: white;
  font-family: 'Capsules';
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

ul {
  margin: 0;
}
ul.dashed {
  padding-left: 20px;
  list-style-type: none;
}
/* ul.dashed > li {
  margin-bottom: 1.5rem;
} */
ul.dashed > li:before {
  content: "-";
  margin-left: -20px;
  margin-right: 10px;
  margin-top: -10px;
}

.address-container .address {
  position: absolute;
  padding: 10px;
  background: black;
  font-size: 0.8rem;
  display: none;
  text-align: left;
  color: white;
  border: 1px solid white;
  z-index: 10;
}

.address-container:hover .address {
  display: block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar  {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

/* @font-face {
  font-family: 'Capsules';
  font-weight: 100;
  font-style: normal;
  src: url(./fonts/Capsules-100.woff) format('opentype');
}

@font-face {
  font-family: 'Capsules';
  font-weight: 200;
  font-style: normal;
  src: url(./fonts/Capsules-200.woff) format('opentype');
}

@font-face {
  font-family: 'Capsules';
  font-weight: 300;
  font-style: normal;
  src: url(./fonts/Capsules-300.woff) format('opentype');
}

@font-face {
  font-family: 'Capsules';
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/Capsules-400.woff) format('opentype');
}

@font-face {
  font-family: 'Capsules';
  font-weight: 500;
  font-style: normal;
  src: url(./fonts/Capsules-500.woff) format('opentype');
}

@font-face {
  font-family: 'Capsules';
  font-weight: 600;
  font-style: normal;
  src: url(./fonts/Capsules-600.woff) format('opentype');
}

@font-face {
  font-family: 'Capsules';
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/Capsules-700.woff) format('opentype');
} */

@font-face {
	font-family: 'Capsules';
	src: url('./fonts/CapsulesVF.woff2') format('woff2');
	font-weight: 100 700;
}

@font-face {
	font-family: 'Capsules';
	src: url('./fonts/CapsulesVF.woff2') format('woff2') tech('variations');
	font-weight: 100 700;
}
